import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
          <path d="M7133 9849 c-1380 -114 -2553 -1109 -2887 -2449 -92 -366 -96 -427
-96 -1414 l0 -841 320 -3 320 -2 0 570 0 570 1320 0 1320 0 0 324 c0 248 -3
325 -12 328 -16 6 -27 1368 -20 2301 l5 627 -84 -1 c-46 -1 -130 -6 -186 -10z"/>
<path d="M7552 9473 l3 -328 140 -8 c508 -27 986 -200 1410 -507 338 -245 627
-590 810 -965 162 -331 241 -628 267 -1002 l12 -163 328 0 328 0 0 63 c0 194
-43 508 -101 744 -108 442 -342 907 -639 1273 -120 148 -376 402 -510 506
-466 362 -962 581 -1520 673 -166 27 -311 41 -431 41 l-99 0 2 -327z"/>
<path d="M7550 8352 l0 -330 68 -6 c413 -37 721 -176 998 -451 270 -268 415
-588 449 -987 l7 -78 329 0 329 0 0 39 c0 122 -41 394 -82 541 -31 115 -103
292 -166 415 -358 689 -1042 1132 -1824 1181 l-108 7 0 -331z"/>
<path d="M7927 7389 c-233 -35 -403 -268 -367 -502 43 -277 318 -448 580 -360
68 23 170 95 212 151 52 68 77 128 87 213 38 299 -213 543 -512 498z"/>
<path d="M7925 5997 c-133 -35 -195 -110 -195 -234 0 -125 57 -179 248 -239
139 -44 177 -72 177 -133 0 -31 -7 -47 -28 -68 -25 -25 -35 -28 -96 -28 -59 0
-73 3 -95 24 -14 13 -28 37 -31 53 l-7 29 -86 -3 -87 -3 3 -30 c14 -124 135
-207 304 -208 78 0 93 3 153 32 79 39 119 88 135 165 20 95 -15 179 -94 229
-23 14 -92 42 -154 62 -138 45 -172 70 -172 128 0 23 4 47 8 53 30 43 117 58
173 28 17 -8 37 -30 45 -50 l14 -34 89 0 89 0 -10 43 c-5 23 -17 55 -27 72
-22 39 -92 92 -139 105 -62 17 -164 20 -217 7z"/>
<path d="M8400 6002 c0 -4 62 -194 138 -422 l139 -415 170 -3 170 -2 138 412
c76 227 140 419 143 426 3 9 -28 12 -134 12 l-139 0 -39 -133 c-66 -227 -127
-431 -132 -447 -3 -8 -35 82 -70 200 -36 118 -76 252 -90 298 l-25 82 -134 0
c-74 0 -135 -3 -135 -8z"/>
<path d="M9330 5905 l0 -105 110 0 110 0 0 -320 0 -320 130 0 130 0 0 320 0
320 110 0 110 0 0 105 0 105 -350 0 -350 0 0 -105z"/>
<path d="M10110 5730 c0 -261 1 -284 22 -347 18 -55 33 -79 78 -124 80 -81
141 -102 280 -97 87 3 115 8 160 29 77 36 121 77 161 151 l34 63 3 303 3 302
-125 0 -125 0 -3 -267 c-3 -236 -5 -271 -21 -294 -31 -46 -97 -62 -152 -37
-52 23 -55 44 -55 333 l0 265 -130 0 -130 0 0 -280z"/>
<path d="M5170 5993 c-1 -5 -2 -194 -3 -421 l-2 -412 83 0 82 0 0 180 0 180
133 0 132 0 2 59 c2 41 -2 61 -11 64 -7 3 -68 5 -135 4 l-121 -2 0 110 0 110
175 -3 175 -3 0 71 0 70 -255 0 c-140 0 -255 -3 -255 -7z"/>
<path d="M5907 5587 c-81 -226 -147 -415 -147 -419 0 -5 38 -8 84 -8 81 0 84
1 90 25 4 14 16 50 27 80 l20 55 169 0 168 0 28 -80 29 -80 86 0 86 0 -151
420 -151 420 -95 0 -95 0 -148 -413z m303 51 c30 -90 56 -169 58 -175 3 -10
-25 -13 -117 -13 -67 0 -121 3 -121 7 0 14 115 343 120 343 3 0 30 -73 60
-162z"/>
<path d="M6675 5992 c-6 -4 -10 -156 -10 -419 l0 -413 82 0 83 0 2 271 3 270
111 -268 111 -268 63 0 63 0 111 270 111 270 3 -273 2 -272 80 0 80 0 -2 418
-3 417 -50 3 c-27 2 -68 1 -90 -3 l-40 -6 -130 -304 c-71 -168 -132 -305 -135
-305 -3 0 -63 137 -135 304 -122 287 -132 305 -159 310 -40 8 -139 7 -151 -2z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
